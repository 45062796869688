import { AlertClassKey } from "@mui/lab";
import {
  Easing,
  PaletteColor,
  PaletteColorOptions,
  Shadows,
  Theme,
  ThemeOptions,
  alpha,
  darken,
  lighten,
  createTheme as muiCreateTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { CSSProperties, Styles } from "@mui/styles";
import SCSSTheme from "./styles/theme.module.scss";
import { deepmerge } from "./utils/objects";

// this fallback is to make tests
// happy: Jest can't seem to load SCSS
function getFromScss(prefix: string, name: string, fallback: string): string;
function getFromScss<T>(prefix: string, name: string, fallback: T, mutate: (str: string, name: string) => T): T;
function getFromScss<T>(
  prefix: string,
  name: string,
  fallback: T,
  mutate: (str: string, name: string) => T = (str) => str as T
): T {
  const scssVal = SCSSTheme[`${prefix}__${name}`];
  return scssVal === undefined ? fallback : mutate(scssVal, name);
}

const getColorFromScss = (name: string, fallback = "#000") => getFromScss("colors", name, fallback);
const getBreakpointFromScss = (name: string, fallback = 960) => getFromScss("breakpoints", name, fallback, parseFloat);
const getDimsFromScss = (name: string, fallback = 100) => getFromScss("dims", name, fallback, parseFloat);
const getShapeFromScss = (name: string, fallback = 100) => getFromScss("shape", name, fallback, parseFloat);
const getTransitionsFromScss = <N extends "easing" | "duration">(name: N, fallback = 100) =>
  getFromScss("shape", name, fallback, (value, name) => {
    switch (name) {
      case "duration":
        return parseFloat(value);
      default:
        return value;
    }
  }) as N extends "easing" ? string : number;
const getFontWeightFromScss = (name: string, fallback = 100) => getFromScss("typography", name, fallback, parseFloat);

export interface ThemeKeyframeMixin {
  keyframes: Styles<Theme, {}>;
  elementStyles: CSSProperties;
}

// please forward all questions to scott@reclaim.ai -SG
export const BORDER_RADIUS_MAXIMUS_ROUNDINUS = 99999999999999;

export const BUTTON_OUTLINE_BORDER_COLOR = "#dcddde";
export const BUTTON_OUTLINE_PADDING = "11px 17px";
const SLIDER_HEIGHT_MULTIPLIER = 0.75;
const SLIDER_THUMB_HEIGHT_MULTIPLIER = 2;
export const THEME_SPACING = getDimsFromScss("spacing");

export const WAVE_DELAY: ThemeKeyframeMixin = {
  keyframes: {
    "@keyframes wave_delay_keyframes": {
      "0%": { transform: "translateY(0)" },
      "3%": { transform: "translateY(3px)" },
      "6%": { transform: "translateY(0)" },
      "100%": { transform: "translateY(0)" },
    },
  },
  elementStyles: {
    animation: "$wave_delay_keyframes 7s ease infinite",
  },
};

export const WAVE: ThemeKeyframeMixin = {
  keyframes: {
    "@keyframes wave_keyframes": {
      "0%": { transform: "translateY(0)" },
      "50%": { transform: "translateY(3px)" },
      "100%": { transform: "translateY(0)" },
    },
  },
  elementStyles: {
    animation: "$wave_keyframes .5s ease infinite",
  },
};

export const OPACITY_PULSE: ThemeKeyframeMixin = {
  keyframes: {
    "@keyframes opacity_pulse_keyframes": {
      from: { opacity: 0.5 },
      to: { opacity: 0.15 },
    },
  },
  elementStyles: {
    animation: "$opacity_pulse_keyframes 1s infinite alternate",
  },
};

export const PULSE_RING: ThemeKeyframeMixin = {
  keyframes: {
    "@keyframes pulse_ring_keyframes": {
      "0%": { transform: "scale(.25)" },
      "40%": { opacity: 0 },
      "100%": { opacity: 0 },
    },
  },
  elementStyles: {
    animation: "$pulse_ring_keyframes 1.75s cubic-bezier(0.215, 0.61, 0.355, 1) infinite",
  },
};

export const PULSE_ORB: ThemeKeyframeMixin = {
  keyframes: {
    "@keyframes pulse_orb_keyframes": {
      "0%": { transform: "scale(.9)", opacity: 0.15 },
      "50%": { transform: "scale(1.2)", opacity: 1 },
      "100%": { transform: "scale(.9)", opacity: 0.15 },
    },
  },
  elementStyles: {
    animation: "$pulse_orb_keyframes 1.75s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite",
  },
};

export const RIPPLE: ThemeKeyframeMixin = {
  keyframes: {
    "@keyframes ripple_keyframes": {
      "0%": { transform: "scale(1)", opacity: 1 },
      "100%": { transform: "scale(1000)", opacity: 0 },
    },
  },
  elementStyles: {
    animation: "$ripple_keyframes .7s ease",
    borderRadius: "50%",
    height: 1,
    width: 1,
  },
};

export type BreakpointKey = "xs" | "sm" | "md" | "lg" | "xl";

export const BREAKPPOINTS: Record<BreakpointKey, number> = {
  xs: getBreakpointFromScss("xs"),
  sm: getBreakpointFromScss("sm"),
  md: getBreakpointFromScss("md"),
  lg: getBreakpointFromScss("lg"),
  xl: getBreakpointFromScss("xl"),
};

export const MUI_BUTTON_LIKE = (theme: Theme): CSSProperties => ({
  fontSize: "1em",
  border: `1px solid ${BUTTON_OUTLINE_BORDER_COLOR}`,
  height: "2em",
  display: "flex",
  alignItems: "center",
  padding: "0 1em",
  borderRadius: "1em",
  textAlign: "center",
  flex: "0 0 auto",
  boxSizing: "border-box",
  justifyContent: "center",
});

// to be used with "outlined" variant
export const SOFT_OUTLINED_TEXT_FIELD_MIXIN = (theme: Theme): CSSProperties => ({
  "& .MuiInputBase-root": {
    border: theme.colors.borderGrey,
    borderRadius: theme.spacing(0.75),
    background: "white",
  },
});

/**
 * Hacks in a `:last-of-selector` pseudo class
 * similar to `:last-of-type` but for any
 * selector instead of just element type.
 *
 * Works by applying `lastCSS` to all elements
 * matching `selector` then applying
 * `overrideCSS` to all but the last match.
 * @param selector The selector which should be searched for
 * @param lastCSS The CSS to be applied to the last match
 * @param overrideCSS The CSS to override `lastCSS` on all other matching elements
 * @returns `CSSProperties` which act as a `:last-of-selector`
 */
export const LAST_OF_SELECTOR_MIXIN = (
  selector: string,
  lastCSS: CSSProperties,
  overrideCSS: CSSProperties
): CSSProperties => ({
  [`& ${selector}`]: {
    ...lastCSS,
  },

  // TODO: :has is not supported in FF yet, but I honestly
  // have no idea how else to isolate a last-of-selector
  // cleanly
  [`& ${selector}:has(+ ${selector})`]: {
    ...overrideCSS,
  },
});

/**
 * Hacks in a `:first-of-selector` pseudo class
 * similar to `:first-of-type` but for any
 * selector instead of just element type.
 * @param selector The selector which should be searched for
 * @param css The CSS to be applied to the first match
 * @returns `CSSProperties` which act as a `:first-of-selector`
 */
export const FIRST_OF_SELECTOR_MIXIN = (selector: string, css: CSSProperties): CSSProperties => ({
  [`& :not(${selector}) + ${selector}`]: {
    ...css,
  },
});

// to be used with "outlined" variant
export const SOFT_OUTLINED_ROUND_TEXT_FIELD_MIXIN = (theme: Theme): CSSProperties => {
  const base = SOFT_OUTLINED_TEXT_FIELD_MIXIN(theme);
  return {
    ...base,
    "& .MuiInputBase-root": {
      ...(base["& .MuiInputBase-root"] as CSSProperties),
      borderRadius: theme.spacing(2.5),
    },
  };
};

// console.log("=>", SCSSTheme, undefined);

/* Logo */
export const THEME_PALETTE_LOGO = {
  yeti: "rgb(234, 237, 240)",
  darkness: getColorFromScss("darkness"),
  darkness100: getColorFromScss("darkness100"),
  shrimp: "rgb(238, 154, 178)",
  blurple: "rgb(82, 99, 243)",
  blurple400: "rgb(82, 99, 243, .40)",
  corn: "rgb(255, 197, 80)",
  corn200: "rgba(255, 197, 80, .20)",
};

/* Palette */
export const THEME_PALETTE = {
  primary: THEME_PALETTE_LOGO.blurple,
  secondary: THEME_PALETTE_LOGO.shrimp,
  dialog: "#2F2E41",
  red: "rgb(217, 3, 3)",
  orange: "rgb(241, 143, 1)",
  yellow: "rgb(241, 211, 1)",
  green: "rgb(153, 194, 77)",
  blue: "#5562eb",
  grey: "rgb(159, 162, 163)",
  textGrey: "#757575",
  softGrey: getColorFromScss("softGrey"),
  borderGrey: getColorFromScss("borderGrey"),
  bgGrey: "#EBECF0",
  white: "rgb(251, 252, 254)",
  black: getColorFromScss("black"),
  logo: THEME_PALETTE_LOGO,
  linkedin: "#0072B1",
  twitter: "#1DA1F2",
  warning: {
    light: getColorFromScss("warningLight"),
    main: getColorFromScss("warningMain"),
    dark: getColorFromScss("warningDark"),
  },
  error: {
    light: getColorFromScss("errorLight"),
    main: getColorFromScss("errorMain"),
    dark: getColorFromScss("errorDark"),
  },
};

/* Stats */
export const THEME_PALETTE_STATS = {
  midnight: getColorFromScss("statsMidnight"), //DEEP_WORK
  blurple: getColorFromScss("statsBlurple"), //SHALLOW_WORK
  cornflower: getColorFromScss("statsCornflower"), //BREAK
  cheese: getColorFromScss("statsCheese"), //TRAVEL
  corn: getColorFromScss("statsCorn"), //PERSONAL
  butter: getColorFromScss("statsButter"), //VACATION
  pink: getColorFromScss("statsPink"), //ONE_ON_ONE
  shrimp: getColorFromScss("statsShrimp"), //TEAM_MEETING
  salmon: getColorFromScss("statsSalmon"), //EXTERNAL_MEETING
  grey: getColorFromScss("statsGrey"), //UNKNOWN
};

// FIXME (IW): This has moved to user-configurable settings, reconcile all uses and delete
export const PRIORITIES_PALETTE: { [key: string]: PaletteColor } = {
  0: {
    main: "#FC9496",
    light: lighten("#FC9496", 0.5),
    dark: darken("#FC9496", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  1: {
    main: "#FCE1A1",
    light: lighten("#FCE1A1", 0.5),
    dark: darken("#FCE1A1", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  2: {
    main: "#8DDFAB",
    light: lighten("#8DDFAB", 0.5),
    dark: darken("#8DDFAB", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  3: {
    main: "#6DBCFE",
    light: lighten("#6DBCFE", 0.5),
    dark: darken("#6DBCFE", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  4: {
    main: "#BD82CC",
    light: lighten("#BD82CC", 0.5),
    dark: darken("#BD82CC", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  other: {
    main: "#9AA0A8",
    light: lighten("#9AA0A8", 0.5),
    dark: darken("#9AA0A8", 0.15),
    contrastText: THEME_PALETTE.black,
  },
  default: {
    main: "#bdbdbd",
    light: "#f5f5f5",
    dark: "#212121",
    contrastText: THEME_PALETTE.black,
  },
};

const shadows: number[][][] = [
  [
    [0, 1, 3, 0],
    [0, 1, 1, 0],
    [0, 2, 1, -1],
  ],
  [
    [0, 1, 5, 0],
    [0, 2, 2, 0],
    [0, 3, 1, -2],
  ],
  [
    [0, 1, 8, 0],
    [0, 3, 4, 0],
    [0, 3, 3, -2],
  ],
  [
    [0, 2, 4, -1],
    [0, 4, 5, 0],
    [0, 1, 10, 0],
  ],
  [
    [0, 3, 5, -1],
    [0, 5, 8, 0],
    [0, 1, 14, 0],
  ],
  [
    [0, 3, 5, -1],
    [0, 6, 10, 0],
    [0, 1, 18, 0],
  ],
  [
    [0, 4, 5, -2],
    [0, 7, 10, 1],
    [0, 2, 16, 1],
  ],
  [
    [0, 5, 5, -3],
    [0, 8, 10, 1],
    [0, 3, 14, 2],
  ],
  [
    [0, 5, 6, -3],
    [0, 9, 12, 1],
    [0, 3, 16, 2],
  ],
  [[0, 4, 16, -3]],
  [
    [0, 6, 7, -4],
    [0, 11, 15, 1],
    [0, 4, 20, 3],
  ],
  [
    [0, 7, 8, -4],
    [0, 12, 17, 2],
    [0, 5, 22, 4],
  ],
  [
    [0, 7, 8, -4],
    [0, 13, 19, 2],
    [0, 5, 24, 4],
  ],
  [
    [0, 7, 9, -4],
    [0, 14, 21, 2],
    [0, 5, 26, 4],
  ],
  [
    [0, 8, 9, -5],
    [0, 15, 22, 2],
    [0, 6, 28, 5],
  ],
  [
    [0, 8, 10, -5],
    [0, 16, 24, 2],
    [0, 6, 30, 5],
  ],
  [
    [0, 8, 11, -5],
    [0, 17, 26, 2],
    [0, 6, 32, 5],
  ],
  [
    [0, 9, 11, -5],
    [0, 18, 28, 2],
    [0, 7, 34, 6],
  ],
  [
    [0, 9, 12, -6],
    [0, 19, 29, 2],
    [0, 7, 36, 6],
  ],
  [
    [0, 10, 13, -6],
    [0, 20, 31, 3],
    [0, 8, 38, 7],
  ],
  [
    [0, 10, 13, -6],
    [0, 21, 33, 3],
    [0, 8, 40, 7],
  ],
  [
    [0, 10, 14, -6],
    [0, 22, 35, 3],
    [0, 8, 42, 7],
  ],
  [
    [0, 11, 14, -7],
    [0, 23, 36, 3],
    [0, 9, 44, 8],
  ],
  [
    [0, 11, 15, -7],
    [0, 24, 38, 3],
    [0, 9, 46, 8],
  ],
];

const shadowKeyUmbraOpacity = 0.06;
const shadowKeyPenumbraOpacity = 0.03;
const shadowAmbientShadowOpacity = 0.05;

const shadowpacities = [shadowKeyUmbraOpacity, shadowKeyPenumbraOpacity, shadowAmbientShadowOpacity];

function createShadow(color: string, args: number | number[][]) {
  const data: number[][] = Array.isArray(args) ? args : shadows[args];
  return data
    .map((part, i) => `${part[0]}px ${part[1]}px ${part[2]}px ${part[3]}px ${alpha(color, shadowpacities[i])}`)
    .join(",");
}

declare module "@mui/material/styles/overrides" {
  /**
   * MUI uses this interface to determine
   * what can be overriden, but it's incomplete.
   * Augment it here to add additional things
   * to overrides.
   */
  interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
  }
}

declare module "@mui/material/styles/createTheme" {
  interface Theme {
    colors: typeof THEME_PALETTE;
    priorities: { [key: string]: PaletteColor };
    util: {
      createShadow: (color: string, args: number | number[][]) => string;
      shadowData: number[][][];
    };
  }

  interface ThemeOptions {
    colors?: Partial<typeof THEME_PALETTE>;
    priorities?: { [key: string]: PaletteColorOptions };
    util?: {
      createShadow: (color: string, args: number | number[][]) => string;
      shadowData: number[][][];
    };
  }
}

export const base: ThemeOptions = {
  transitions: {
    // typing is incorrect, this should be a string
    easing: getTransitionsFromScss("easing") as unknown as Easing,
    duration: {
      standard: getTransitionsFromScss("duration"),
    },
  },
  breakpoints: {
    values: BREAKPPOINTS,
  },
  colors: THEME_PALETTE,
  spacing: THEME_SPACING,
  shape: {
    borderRadius: getShapeFromScss("borderRadius"),
  },
  components: {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: "1em",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: 16,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        modifiers: [
          {
            name: "flip",
            options: {
              enabled: false,
            },
          },
          {
            name: "preventOverflow",
            options: {
              enabled: true,
            },
          },
        ],
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 12,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: THEME_SPACING * SLIDER_HEIGHT_MULTIPLIER,
        },
        rail: {
          height: "inherit",
          borderRadius: THEME_SPACING * (SLIDER_HEIGHT_MULTIPLIER / 2),
        },
        track: {
          height: "inherit",
          borderRadius: THEME_SPACING * (SLIDER_HEIGHT_MULTIPLIER / 2),
        },
        thumb: {
          width: THEME_SPACING * SLIDER_THUMB_HEIGHT_MULTIPLIER,
          height: THEME_SPACING * SLIDER_THUMB_HEIGHT_MULTIPLIER,
          marginTop: -(THEME_SPACING * SLIDER_THUMB_HEIGHT_MULTIPLIER) / 3,
          marginLeft: -(THEME_SPACING * SLIDER_THUMB_HEIGHT_MULTIPLIER) / 3,

          "&.Mui-disabled": {
            width: THEME_SPACING * SLIDER_THUMB_HEIGHT_MULTIPLIER,
            height: THEME_SPACING * SLIDER_THUMB_HEIGHT_MULTIPLIER,
            marginTop: -(THEME_SPACING * SLIDER_THUMB_HEIGHT_MULTIPLIER) / 3,
            marginLeft: -(THEME_SPACING * SLIDER_THUMB_HEIGHT_MULTIPLIER) / 3,
          },
        },
        markLabel: {
          fontWeight: 600,
        },
        valueLabel: {
          left: "-50%",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottomColor: THEME_PALETTE_LOGO.darkness100,
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          maxWidth: "100vw",
        },
        indicator: {
          borderRadius: "4px 4px 0 0",
          height: "4px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: "1.3334em",
          "&:hover": {
            backgroundColor: "rgba(46, 51, 59, 0.04)",
          },
        },
        outlined: {},
        standard: {
          borderRadius: 40,
          paddingLeft: 14,
          "&:focus": {
            borderRadius: 40,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: lighten(THEME_PALETTE.black, 0.1),
          fontSize: "1.06em",
          textTransform: "none",
          marginBottom: "8px",
          padding: "0px 24px",
          backgroundColor: "transparent",
          borderRadius: 8,
          [`@media (min-width: ${BREAKPPOINTS["sm"]}px)`]: {
            minWidth: 0,
          },
          maxWidth: "none",
          transition: "background-color .2s ease-in",
          "&:hover": {
            backgroundColor: lighten(THEME_PALETTE_LOGO.darkness100, 0.5),
          },
          "&.Mui-selected": {
            fontWeight: 600,
            color: THEME_PALETTE_LOGO.darkness,
          },
        },
        textColorInherit: {
          opacity: 1,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 8,
          borderRadius: 4,
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
        bar: {
          borderRadius: 4,
        },
        colorPrimary: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
        colorSecondary: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        adornedStart: {
          paddingLeft: 12,
        },
        adornedEnd: {
          paddingRight: 12,
        },
        input: {
          height: "auto",
          lineHeight: 1.3334,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        input: {
          padding: 14,
        },
        inputSizeSmall: {
          padding: "9px 14px",
        },
        notchedOutline: {
          borderColor: "rgba(0,0,0,.15)",
        },
        adornedStart: {
          paddingLeft: 12,
        },
        adornedEnd: {
          paddingRight: 12,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: "transparent",
          border: "1px solid rgba(0,0,0,.15)",
          borderRadius: 4,
          padding: 0,
          "&:not(.Mui-disabled, .Mui-error):before, &:hover:not(.Mui-disabled, .Mui-error):before, &:not(.Mui-disabled, .Mui-error):after":
            {
              borderBottom: 0,
            },
          "&:hover": {
            backgroundColor: "rgba(46, 51, 59, 0.04)",
            borderColor: "black",
          },
          "&.Mui-focused": {
            backgroundColor: "transparent",
            borderColor: THEME_PALETTE.primary,
            boxShadow: `0 0 0 1px ${THEME_PALETTE.primary}`,
          },
        },
        input: {
          padding: "24px 12px 8px",
        },
        inputSizeSmall: {
          padding: "20px 12px 4px",
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "40px",
        },
        groupedHorizontal: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "40px",
          lineHeight: 1.2,
          textTransform: "none",
          "&$disabled": {
            "&:hover": {
              border: "1px solid rgba(0,0,0,0.12)",
              textDecoration: "none",
            },
          },
        },
        contained: {
          padding: "12px 18px",
        },
        containedSizeSmall: {
          padding: "8px 14px",
        },
        containedSizeLarge: {
          padding: "16px 24px",
        },
        outlined: {
          borderColor: BUTTON_OUTLINE_BORDER_COLOR,
          padding: BUTTON_OUTLINE_PADDING,
        },
        outlinedSizeSmall: {
          padding: "7px 13px",
        },
        outlinedSizeLarge: {
          padding: "15px 23px",
        },
        endIcon: {
          marginLeft: 4,
        },
        startIcon: {
          marginLeft: 0,
          marginRight: 4,
        },
        iconSizeMedium: {
          "&> *:first-child": {
            fontSize: 16,
          },
        },
        iconSizeLarge: {
          "&> *:first-child": {
            fontSize: 18,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: 12,
          fontWeight: 500,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          fontWeight: 500,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&$disabled": {
            opacity: 0.5,
          },
        },
        sizeSmall: {
          padding: 3,
          "& svg": {
            width: 16,
            height: 16,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&$focused": {
            color: "inherit",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: 4.5,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: "normal",
        },
        contained: {
          marginLeft: "0.25em",
          marginRight: "0.25em",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          backgroundColor: alpha("#fff", 0.25),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: "#DFE0EB",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        standardWarning: {
          backgroundColor: THEME_PALETTE.warning.light,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha(THEME_PALETTE.black, 0.95),
          fontSize: "0.8125rem",
          maxWidth: 250,
          "& .MuiLink-root": {
            color: lighten(THEME_PALETTE.primary, 0.25),
            "&:hover": {
              color: lighten(THEME_PALETTE.primary, 0.45),
            },
          },
          "& .MuiTypography-caption": {
            fontWeight: 400,
            lineHeight: 1.4,
          },
        },
        arrow: {
          color: alpha(THEME_PALETTE.black, 0.95),
        },
      },
    },
    MuiBadge: {
      defaultProps: {
        overlap: "rectangular",
      },

      styleOverrides: {
        badge: {
          borderRadius: 4,
        },
        colorSecondary: {
          color: THEME_PALETTE.black,
        },
      },
    },
    MuiTouchRipple: {},
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "& .MuiListItemIcon-root": {
            minWidth: 16,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          "&&&": {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: THEME_PALETTE.primary,
      contrastText: THEME_PALETTE.white,
    },
    secondary: {
      main: THEME_PALETTE.secondary,
      contrastText: THEME_PALETTE.white,
    },
    error: {
      main: THEME_PALETTE.red,
      contrastText: THEME_PALETTE.white,
    },
    common: {
      white: THEME_PALETTE.white,
      black: THEME_PALETTE.black,
    },
    info: {
      main: THEME_PALETTE.primary,
    },
    warning: {
      main: THEME_PALETTE.warning.main,
      dark: THEME_PALETTE.warning.dark,
      light: THEME_PALETTE.warning.light,
    },
  },
  typography: {
    fontWeightRegular: getFontWeightFromScss("fontWeightRegular"),
    fontWeightMedium: getFontWeightFromScss("fontWeightMedium"),
    fontWeightBold: getFontWeightFromScss("fontWeightBold"),
    fontFamily: [
      "Poppins",
      "-apple-system",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    body1: {
      fontSize: "0.9375rem",
    },
    body2: {
      fontSize: "0.8125rem",
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: "1.125rem",
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: "1.0625rem",
      lineHeight: 1.4,
    },
    h1: {
      fontSize: "1.8125rem",
      fontWeight: 600,
      letterSpacing: "-.03em",
    },
    h2: {
      fontSize: "1.8125rem",
      fontWeight: 600,
      letterSpacing: "-.02em",
    },
    h3: {
      fontSize: "1.5625rem",
      fontWeight: 600,
      letterSpacing: "-.02em",
    },
    h4: {
      fontSize: "1.35rem",
      fontWeight: 600,
      letterSpacing: "-.02em",
      "@media (min-width:1280px)": {
        fontSize: "1.35rem",
      },
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 600,
      letterSpacing: "-.02em",
    },
    h6: {
      fontSize: "0.9375rem",
      fontWeight: 600,
      letterSpacing: "-.02em",
      lineHeight: 1.4,
    },
  },
  shadows: ["none"].concat(shadows.map((data) => createShadow(THEME_PALETTE.black, data))) as Shadows,
  priorities: PRIORITIES_PALETTE,
  util: {
    createShadow,
    shadowData: shadows,
  },
};

export const light: ThemeOptions = {
  palette: {
    mode: "light",
    text: {
      primary: lighten(THEME_PALETTE.black, 0.1),
      secondary: lighten(THEME_PALETTE.black, 0.4),
      disabled: "rgba(0, 0, 0, 0.35)",
    },
    divider: "rgba(0, 0, 0, 0.075)",
    background: {
      default: "#F7F8FC",
      paper: "#edf1f7",
    },
  },
};

export const dark: ThemeOptions = {
  palette: {
    mode: "dark",
    text: {
      primary: darken(THEME_PALETTE.white, 0.1),
      secondary: THEME_PALETTE.secondary,
      disabled: "rgba(0, 0, 0, 0.35)",
    },
    divider: "rgba(0, 0, 0, 0.075)",
    background: {
      default: THEME_PALETTE.black,
      paper: "#e4e4ea",
    },
    common: {
      white: THEME_PALETTE.black,
      black: THEME_PALETTE.white,
    },
  },
};

export const createTheme = (options: ThemeOptions): Theme => {
  return responsiveFontSizes(
    process.env.NODE_ENV === "test" ? muiCreateTheme() : muiCreateTheme(deepmerge({}, base, options))
  );
};

export const AppTheme = {
  Dark: createTheme(dark),
  Light: createTheme(light),
};

export default AppTheme;
